.horizontal-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
